<template>
  <div>
    <el-descriptions title="维修设备信息" :style="isHandle ? style1 : null">
      <el-descriptions-item label="设备名称">{{ formatData(info, 'deviceName') }}</el-descriptions-item>
      <el-descriptions-item label="设备型号">{{ formatData(info, 'deviceModel') }}</el-descriptions-item>
      <el-descriptions-item label="设备品牌">{{ formatData(info, 'deviceBrandName') }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :style="isHandle ? style2 : null">
      <el-descriptions-item label="故障描述">{{ formatData(info, 'faultDescription') }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :style="isHandle ? style3 : null">
      <el-descriptions-item label="故障图片">
        <upload size="mini" :disabled="true" :value="info.faultImageList || []" />
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="物流信息" :colon="false" :style="isHandle ? style1 : null">
      <el-descriptions-item :labelStyle="{ width: 0, margin: 0 }" contentClassName="catogory-tag">
        <span :class="[activeName === 'sendout' ? 'active' : '']" @click="activeName = 'sendout'">备用机寄出信息</span>
        <span :class="[activeName === 'signfor' ? 'active' : '']" @click="activeName = 'signfor'">
          备用机寄出签收信息
        </span>
        <span :class="[activeName === 'sendback' ? 'active' : '']" @click="activeName = 'sendback'">
          备用机寄回信息
        </span>
        <span :class="[activeName === 'returnAndSign' ? 'active' : '']" @click="activeName = 'returnAndSign'">
          备用机寄回签收信息
        </span>
      </el-descriptions-item>
    </el-descriptions>
    <template v-if="activeName === 'sendout'">
      <template v-if="info.spareSendOutDTO">
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="快递公司">
            {{ formatData(info.spareSendOutDTO, 'courierName') }}
          </el-descriptions-item>
          <el-descriptions-item label="快递单号">
            <span style="color: #237ffa; cursor: pointer" @click="() => showLogisticDetail('spareSendOutDTO')">
              {{ formatData(info.spareSendOutDTO, 'courierNumber') }}{{ getLogisticNode('spareSendOutDTO') }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="寄出备注">{{ formatData(info.spareSendOutDTO, 'remark') }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="寄出设备图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendOutDTO, 'deviceAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style3 : null">
          <el-descriptions-item label="寄出包装图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendOutDTO, 'wrapAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <el-empty
        v-else
        style="background-color: #fff"
        description="暂无信息"
        :image="require('@/assets/empty.png')"
      ></el-empty>
    </template>
    <template v-if="activeName === 'signfor'">
      <template v-if="info.spareSendOutSignDTO">
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="签收备注">
            {{ formatData(info.spareSendOutSignDTO, 'remark') }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="备用机签收图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendOutSignDTO, 'deviceAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="备用机包装签收图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendOutSignDTO, 'wrapAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <el-empty
        v-else
        style="background-color: #fff"
        description="暂无信息"
        :image="require('@/assets/empty.png')"
      ></el-empty>
    </template>
    <template v-if="activeName === 'sendback'">
      <template v-if="info.spareSendBackDTO">
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="快递公司">
            {{ formatData(info.spareSendBackDTO, 'courierName') }}
          </el-descriptions-item>
          <el-descriptions-item label="快递单号">
            <span style="color: #237ffa; cursor: pointer" @click="() => showLogisticDetail('spareSendBackDTO')">
              {{ formatData(info.spareSendBackDTO, 'courierNumber') }}{{ getLogisticNode('spareSendBackDTO') }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="寄回备注">
            {{ formatData(info.spareSendBackDTO, 'remark') }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="设备图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendBackDTO, 'deviceAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style3 : null">
          <el-descriptions-item label="设备包装图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendBackDTO, 'wrapAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <el-empty
        v-else
        style="background-color: #fff"
        description="暂无信息"
        :image="require('@/assets/empty.png')"
      ></el-empty>
    </template>
    <template v-if="activeName === 'returnAndSign'">
      <template v-if="info.spareSendBackSignDTO">
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="外包装情况">
            {{ formatData(info.spareSendBackSignDTO, 'wrapFlagMsg') }}
          </el-descriptions-item>
          <el-descriptions-item label="设备外观">
            {{ formatData(info.spareSendBackSignDTO, 'facadeFlagMsg') }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="签收备注">
            {{ formatData(info.spareSendBackSignDTO, 'remark') }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style2 : null">
          <el-descriptions-item label="签收设备图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendBackSignDTO, 'deviceAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :style="isHandle ? style3 : null">
          <el-descriptions-item label="签收包装图片">
            <upload size="mini" :disabled="true" :value="getImageList(info.spareSendBackSignDTO, 'wrapAnnexList')" />
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <el-empty
        v-else
        style="background-color: #fff"
        description="暂无信息"
        :image="require('@/assets/empty.png')"
      ></el-empty>
    </template>
    <ExpressDetailDialog ref="expressDetailDialog"></ExpressDetailDialog>
  </div>
</template>

<script>
import Upload from '@/components/common/upload';
import ExpressDetailDialog from '../../components/expressDetailDialog.vue';

export default {
  components: {
    Upload,
    ExpressDetailDialog,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '备件码',
          dataIndex: 'code',
          key: 'code',
          width: 120,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
        {
          title: '备件图片',
          dataIndex: 'imageList',
          key: 'imageList',
          width: 120,
          scopedSlots: { customRender: 'imageList' },
          ellipsis: true,
        },
        {
          title: '备件名称',
          dataIndex: 'name',
          key: 'name',
          width: 120,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
        {
          title: '备件型号',
          dataIndex: 'model',
          key: 'model',
          width: 120,
          ellipsis: true,
          customRender: text => {
            return text ? text : '--';
          },
        },
        {
          title: '数量（单位）',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'quantity' },
        },
      ],
      style1: {
        'margin-top': '8px',
        padding: '18px 18px 0',
        'border-bottom-left-radius': 0,
        'border-bottom-right-radius': 0,
      },
      style2: {
        padding: '0 18px 0',
        'border-radius': 0,
      },
      style3: {
        padding: '0 18px 0',
        'border-top-left-radius': 0,
        'border-top-right-radius': 0,
      },
      style4: {
        'margin-top': '8px',
        padding: '18px 18px 0',
        'border-radius': '6px',
      },
      activeName: 'sendout',
    };
  },
  methods: {
    formatData (obj, key) {
      return obj?.[key] ? obj[key] : '--';
    },
    getImageList (obj, key) {
      return !obj || !obj[key] ? [] : obj[key];
    },
    getLogisticNode (key) {
      const courierNumber = this.info?.[key]?.courierNumber;
      let ret = '';

      if (courierNumber) {
        const logistics = this.info?.[key]?.logisticsTrackList;

        if (logistics && logistics.length) {
          ret = logistics[0].status ? `_${logistics[0].status}` : '';
        }
      }

      return ret;
    },
    showLogisticDetail (key) {
      const courierNumber = this.info?.[key]?.courierNumber;

      if (courierNumber) {
        const courierName = this.info?.[key]?.courierName;
        const logisticTracks = this.info?.[key]?.logisticsTrackList;

        this.$refs.expressDetailDialog.init(courierName || '', courierNumber || '', logisticTracks || []);
      }
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.el-descriptions {
  background-color: #fff;
  border-radius: 6px;

  .el-descriptions__header {
    margin-bottom: 10px;

    .el-descriptions__title {
      font-size: 14px;
    }
  }

  &.device-table {
    .el-descriptions__header {
      margin-bottom: 0px;
    }

    .el-descriptions-item__content {
      overflow: auto;
    }
  }

  .catogory-tag {
    margin-bottom: 16px;
    span {
      display: inline-block;
      background: #f2f4f7;
      border-radius: 4px;
      padding: 6px 12px;
      margin-right: 16px;
      cursor: pointer;

      &:hover {
        background: #e2f3fe;
        color: #237ffa;
      }

      &.active {
        background: #e2f3fe;
        color: #237ffa;
      }
    }
  }
}
</style>
